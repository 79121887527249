import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Media from 'react-media';
import { Helmet } from "react-helmet";
import { scrollTo } from "./utils";
import './App.css';
import AppContext, { translations, links } from './context';
import {
  Header,
  Main,
  Statistics,
  ShareStory,
  Stories,
  Accomplices,
  Table,
  Footer,
} from './components';

function App() {
  const availableLanguages = ['ru', 'by', 'en'];
  let currentLanguage = window.location.pathname.substring(1).split('/')[0];

  if (!currentLanguage.length || !availableLanguages.includes(currentLanguage)) {
    currentLanguage = availableLanguages[0];
    window.history.replaceState(null, null, `/${currentLanguage}`);
  }

  const languagesLinks = availableLanguages.map(language => {
    return (
      <a
        href={`./${language}`}
        key={language}
        className={classNames('language', {'text-green': currentLanguage === language})}
      >
        {translations[currentLanguage].languages[language]}
      </a>
      )
  })

  const [pageLoaded, setPageLoaded] = useState(false);
  const [showTable, setShowTable] = useState('hidden');
  const [data, setData] = useState({});

  useEffect(() => {
    const sectionHash = window.location.hash.substring(1);

    if (sectionHash.length === 0) {
      setPageLoaded(true);
      return;
    }

    if (pageLoaded && showTable === 'hiding') {
      setShowTable('hidden');
      scrollTo({id: sectionHash});
    }

    if (!pageLoaded && sectionHash === 'fullTable') {
      setShowTable('showed');
    }

    if (!pageLoaded) {
      scrollTo({id: sectionHash});
    }

    setPageLoaded(true);
  }, [pageLoaded, showTable]);

  useEffect(() => {
    fetch(`/info?lang=${currentLanguage}`)
    .then(response => response.json())
    .then(data => setData(data));
  }, []);

  const handleNavigation = (toId = null, duration = 1125, headerOffset = -70) => {
    const params = toId ? { id: toId, duration, headerOffset } : { top: true, duration, headerOffset };
    const path = toId ? `#${toId}` : window.location.pathname;

    if (toId === 'fullTable') {
      params.top = true;
      setShowTable('showed');
    }

    if (toId !== 'fullTable' && showTable === 'showed') {
      setShowTable('hiding');
    }

    scrollTo(params);
    window.history.replaceState(null, null, path);
  }

  const GLOBAL_MEDIA_QUERIES = {
    mobile: "(max-width: 719px)",
    tablet: "(min-width: 720px) and (max-width: 1023px)",
    desktop: "(min-width: 1024px)"
  };

  return (
    <Media queries={GLOBAL_MEDIA_QUERIES}>
      {screenSize => (
        <AppContext.Provider value={{ language: currentLanguage, translations: translations[currentLanguage], links, screenSize }}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{translations[currentLanguage].title}</title>
            <meta name="description" content={`${translations[currentLanguage].main.firstHeader} ${translations[currentLanguage].main.secondHeader}.`} />
          </Helmet>
          <Header navigationHandler={handleNavigation} languages={languagesLinks} />
          <div className="page" style={{overflow: showTable === 'showed' || screenSize.mobile ? null : 'hidden'}}>
            <div className="work-space">
              {
                showTable === 'showed' ? 
                <Table
                  data={data}
                  translations={translations[currentLanguage]}
                  hideTable={() => handleNavigation('accomplices')}
                  language={currentLanguage}
                /> :
                <>
                  <Main data={data} />
                  <Statistics data={data} language={currentLanguage} />
                  <ShareStory />
                  {/* <Stories data={data} /> */}
                  <Accomplices data={data} showTable={() => handleNavigation('fullTable', 400)}
                  />
                  <Footer />
                </>
              }
            </div>
          </div>
        </AppContext.Provider>
      )}
    </Media>
  );
}

export default App;
