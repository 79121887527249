import React, { useEffect, useState } from 'react';
import { useTable, useFilters, useExpanded } from 'react-table'
import Select from 'react-select';
import AnimateHeight from 'react-animate-height';
import { Divider } from '@react-md/divider';
import { Panel } from '.';
import classNames from 'classnames';
import AppContext from '../context';
import photoLogo from '../images/photo.svg';
import { ReactComponent as FilterList } from '../images/filterList.svg';
import { numberFilter, sortLocale } from '../utils';

function SelectColumnFilter({
  column: { setFilter, preFilteredRows, id, Header },
  }, language) {
  const options = React.useMemo(() => {
    let options = new Set()
    const filteredRows = preFilteredRows.map(row => row.values).filter(value => value[id]); 
    const sortedRows = sortLocale(language, filteredRows, id);
    sortedRows.forEach(value => {
      options.add(value[id])
    })
    options = [...options.values()].map(value => ({ value: value, label: value}))
    return [{ value: null, label: Header.default }, ...options]
  }, [id, preFilteredRows, Header.default])

  const [university, setUniversity] = useState({ value: null, label: Header.default });

  const selectStyles = {
    control: (styles) => ({ 
        ...styles, 
        minHeight: 0,
        boxShadow: 'none',
        border: 0,
        backgroundColor: 'transparent',
        color: '#FF4242',
        fill: '#FF4242',

        ':hover': {
            color: '#FFFFFF',
            fill: '#FFFFFF',
            cursor: 'pointer',
            border: 0,
        },
    }),
    container: (styles) => ({
        ...styles,
        maxHeight: 30,
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        maxHeight: 20,
        marginBottom: 2,
        paddingLeft: 8,
        fill: 'inherit'
    }),
    valueContainer: (styles) => ({
        ...styles,
        maxHeight: 20,
        padding: 0,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: 'inherit',
      margin: 0,
    }),
    option: (styles) => ({
        ...styles,
        padding: 0,
        paddingLeft: 20,
        paddingTop: 3,
        color: '#434343',

        ':hover': {
            cursor: 'pointer',
        }
    }),
    menu: (styles) => ({
      ...styles,
      width: 200,
    }),
  }

  return (
    <Select
      options={options}
      styles={selectStyles}
      isSearchable={false}
      components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <FilterList style={{fill: 'inherit'}}/>,
      }}
      onChange={e => {
        setFilter(e.value || undefined);
        setUniversity(e);
      }}
      value={university.value ? {value: university.value, label: `${Header.header}: ${university.label}`} : {value: null, label: Header.header}}
    />
  )
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const Card = ({person, translations, initiallyOpen = false}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyOpen);

  return (
    <div style={{background: '#373737', marginBottom: 15, padding: 16, borderRadius: 5, cursor: 'pointer'}} onClick={()=> setIsExpanded(!isExpanded)}>
      <div style={{display: 'flex', gap: '16px'}}>
        <div className="filter filter-red in-table-photo">
          <img
              src={person.photoUrl || photoLogo}
              alt='profile'
              className="profile-photo"
              style={{mixBlendMode: person.photoUrl ? 'luminosity' : 'unset'}}
          />
        </div>
        <div>
          <p className='small-text'><span className='text-red in-table-header'>{translations.table.name}: </span>{`${person.name} ${person.surname}`}</p>
          <p className='small-text'><span className='text-red in-table-header'>{translations.table.university}: </span>{person.university}</p>
          <p className='small-text'><span className='text-red in-table-header'>{translations.table.position}: </span>{person.position}</p>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 16}}>
        <p className='small-text'><span className='text-red in-table-header'>{translations.table.expelled}: </span>{numberFilter(person.expelled) || '—'}</p>
        <p className='small-text'><span className='text-red in-table-header'>{translations.table.pressure}: </span>{numberFilter(person.pressure) || '—'}</p>
      </div>
      <AnimateHeight height={isExpanded && person.story ? "auto" : 0}>
        <Divider className="divider in-card"/>
        {person.story && <p className='small-text text-grey' style={{marginTop: 16}}>{person.story}</p>}
      </AnimateHeight>
    </div>
  )
}

export const Table = ({translations, hideTable, data, language}) => {
  const repressors = data.repressors || [];

  const tableData = React.useMemo(
    () => [
      ...repressors.filter(repressor => repressor.university),
    ],
    [data]
  )

  const columns = React.useMemo(
    () => [
    {
        Header: '',
        accessor: 'photoUrl',
        Cell: (value) => (
          <div className="filter filter-red in-table-photo">
            <img
                src={value.row.original.photoUrl || photoLogo}
                alt='profile'
                className="profile-photo"
                style={{mixBlendMode: value.row.original.photoUrl ? 'luminosity' : 'unset'}}
            />
          </div>
        )
      },
      {
        Header: translations.table.name,
        accessor: 'name',
        Cell: value => `${value.row.original.name} ${value.row.original.surname}`,
      },
      {
        Header: translations.table.position,
        accessor: 'position',
      },
      {
        Header: {header: translations.table.university, default: translations.table.choice},
        accessor: 'university',
        Filter: (params) => SelectColumnFilter(params, language),
        filter: 'exactText',
      },
      {
        Header: translations.table.expelled,
        accessor: 'expelled',
        Cell: ({ value }) => numberFilter(value),
      },
      {
        Header: translations.table.pressure,
        accessor: 'pressure',
        Cell: ({ value }) => numberFilter(value),
      },
    ],
    [translations.table]
  )

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }), []
  )

  const [universityFilter, setUiversityFilter] = useState('all');
  const [firstLoad, setFirstLoad] = useState(true);

  const universityOptions = React.useMemo(() => {
    const options = new Set()
    const filteredData = tableData.filter(value => value.university);
    sortLocale(language, filteredData, 'university').forEach(value => {
      options.add(value.university);
    });
    return [...options.values()].map(value => ({ value: value, label: value}));
  }, [tableData]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [firstLoad]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: tableData, defaultColumn }, useFilters, useExpanded)

  return (
    <AppContext.Consumer>
        {({translations, screenSize}) => (
          <div className="table-section">
            <div className='table-title'>
              <p className='bold-text text-red' style={{margin: 0}}>{translations.table.header}</p>
              <p className='small-text to-main-button' onClick={hideTable}><span className='to-main-array'>{'<'}</span>{translations.table.backButton}</p>
            </div>
            {screenSize.desktop ? <table {...getTableProps()}>
              <thead>
                  {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => {
                        return(
                          <th
                              {...column.getHeaderProps()}
                              className={"small-text text-red table-header"}
                              style={{
                                padding: 0,
                                width: column.id === 'position' ? '33%' : null,
                                borderRadius: {
                                  'pressure': '0 2px 2px 0',
                                  'photoUrl': '2px 0 0 2px',
                                }[column.id],
                                position: 'sticky',
                                top: 0,
                                zIndex: column.id === 'university' ? 4 : 3,
                                background: 'linear-gradient(360deg, #373737 40px, transparent 40px)'
                              }}
                          >
                              <div style={{paddingTop: 20}} />
                              <div
                                className={classNames({
                                  'column-pressure': column.id === 'pressure',
                                  'column-expelled': column.id === 'expelled',
                                  'column-university': column.id === 'university',
                                  'column-photo': column.id === 'photoUrl',
                                  'column-name': column.id === 'name',
                                  'column-position': column.id === 'position',
                                })}
                                style={{
                                  height: 40,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: ['expelled', 'pressure'].includes(column.id) ? 'flex-end' : 'flex-start',
                                }}>
                                {column.canFilter && column.id === 'university' ? column.render('Filter') : column.render('Header')}
                              </div>
                          </th>
                      )})}
                  </tr>
                  ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                  prepareRow(row)
                  return (
                    <React.Fragment key={row.id}>
                      <tr {...row.getRowProps()} className='table-row'
                        onClick={() => {
                          if (row.original.story && row.original.story.length) {
                            row.toggleRowExpanded(!row.isExpanded);
                          }
                        }}
                      >
                        {row.cells.map(cell => {
                            return (
                            <td
                                {...cell.getCellProps()}
                                className={classNames('text', 'table-data-cell', {
                                  'text-red': cell.column.id === 'name',
                                  'column-pressure': cell.column.id === 'pressure',
                                  'column-expelled': cell.column.id === 'expelled',
                                  'column-university': cell.column.id === 'university',
                                  'column-photo': cell.column.id === 'photoUrl',
                                  'column-name': cell.column.id === 'name',
                                  'column-position': cell.column.id === 'position',
                                })}
                                style={{
                                  paddingLeft: cell.column.id === 'photoUrl' ? '20px' : '0px',
                                  textAlign: ['expelled', 'pressure'].includes(cell.column.id) ? 'end' : 'start',
                                }}
                            >
                                {cell.value === 0 ? '—' : cell.render('Cell')}
                            </td>
                            )
                        })}
                      </tr>
                      {
                        <tr
                          key={row.id}
                          onClick={() => row.toggleRowExpanded(!row.isExpanded)}
                          className='table-row'
                        >
                          <td className='table-data-border'></td>
                          <td colSpan={2} className={classNames('small-text', 'text-grey', {'table-data-border': true})} style={{width: 100, paddingBottom: row.isExpanded ? 0 : 20}}>
                          <AnimateHeight height={!row.isExpanded && row.original.story && row.original.story.length ? "auto" : 0}>
                            {row.original.story}
                          </AnimateHeight>

                          </td>
                          <td colSpan={4} className='table-data-border'></td>
                        </tr>

                      }
                    </React.Fragment>
                  )
                  })}
              </tbody>
            </table>
            : (
              <div>
                 <Panel
                    options={universityOptions}
                    filter={universityFilter}
                    allButtonText={translations.table.allCasesButton}
                    placeholder={translations.statistics.filterButton}
                    onClick={filter => setUiversityFilter(filter)}
                 />
                 <div className='cards'>
                  {tableData.map(person => (
                    universityFilter === 'all' || person.university === universityFilter.value ?
                      <Card 
                        key={person.id}
                        person={person}
                        translations={translations}
                        initiallyOpen={true}
                      />
                      : null
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
    </AppContext.Consumer>
  )
}
