import { useEffect, useState } from 'react';
import { Divider } from "@react-md/divider";
import { Panel } from '.';
import AppContext from '../context';
import { numberFilter, sortLocale } from '../utils'

const InfoCell = ({number, children}) => {
    return (
        <div className="info-cell">
            <p className="heading text-red numbers">{numberFilter(number)}</p>
            <p className="all-caps text-red statistics">
                {children}
            </p>
        </div>
    )
}

export const Statistics = ({ data, language }) => {
    const [universityFilter, setUiversityFilter] = useState('all');
    const [statistics, setStatistics] = useState([]);

    useEffect(() => {
        let values = data.repressions || [];
        values = sortLocale(language, values, 'universityName');
        setStatistics(values);
    }, [data.repressions])

    const universityOptions = statistics.map(({ universityName }) => ({ value: universityName, label: universityName}));
    const filteredStatistics = statistics.length === 0 ? {} : universityFilter === 'all' ? statistics.reduce((prev, current) => {
        const newObj = {}
        for (const key in prev) {
            newObj[key] = prev[key] + current[key]
        }
        return newObj
    }) : statistics.filter(university => university.universityName === universityFilter.value)[0];

    return (
        <AppContext.Consumer>
            {({ translations, screenSize }) => (
                    <div className="section" id="statistics">
                        <p className="heading mobile">{translations.statistics.header}</p>
                        <div className="table">
                            {! screenSize.mobile &&  <div className="divider"/>}
                            <div className="column width-50">
                                <p className="heading in-table text-red numbers">{numberFilter(data.casesNumber)}</p>
                                <p className="all-caps text-red">{translations.statistics.numberDescription}</p>
                                <p className="small-text text-grey" style={{marginTop: screenSize.mobile ? "16px" : "12px"}}>
                                    {translations.statistics.footnote}
                                </p>
                            </div>
                            {! screenSize.mobile && (
                                <>
                                    <div className="divider"/>
                                    <div className="column width-50">
                                        <p className="text text-grey">
                                            {translations.statistics.description}
                                        </p>
                                    </div>
                                    <div className="divider"/>
                                </>
                            )}
                        </div>
                        <Panel
                            options={universityOptions}
                            filter={universityFilter}
                            allButtonText={translations.statistics.allCasesButton}
                            placeholder={translations.statistics.filterButton}
                            onClick={filter => setUiversityFilter(filter)}
                        />
                        <div className="table">
                        {! screenSize.mobile &&  <div className="divider"/>}
                            <div className="column width-50">
                                <p className="text in-table">{translations.statistics.students}</p>
                                <InfoCell number={filteredStatistics.studentsPoliticalPrisoners}>
                                    { filteredStatistics.studentsPoliticalPrisoners === 1 ? translations.statistics.studentsStats.politPrisoners.singular : translations.statistics.studentsStats.politPrisoners.plural }
                                </InfoCell>
                                <InfoCell number={filteredStatistics.studentsExpelled}>
                                    { filteredStatistics.studentsExpelled === 1 ? translations.statistics.studentsStats.expelled.singular : translations.statistics.studentsStats.expelled.plural }
                                </InfoCell>
                                <InfoCell number={filteredStatistics.studentsStipendDormitory}>
                                    { filteredStatistics.studentsStipendDormitory === 1 ? translations.statistics.studentsStats.stripped.singular : translations.statistics.studentsStats.stripped.plural }
                                </InfoCell>
                                <InfoCell number={filteredStatistics.studentsPressured}>
                                    { filteredStatistics.studentsPressured === 1 ? translations.statistics.studentsStats.subjected.singular : translations.statistics.studentsStats.subjected.plural }
                                </InfoCell>
                                <InfoCell number={filteredStatistics.studentsReprimended}>
                                    { filteredStatistics.studentsReprimended === 1 ? translations.statistics.studentsStats.reprimanded.singular : translations.statistics.studentsStats.reprimanded.plural }
                                </InfoCell>
                            </div>
                            {screenSize.mobile ? <Divider className="divider"/> : <div className="divider"/>}
                            <div className="column width-50">
                                <p className="text in-table">{translations.statistics.professors}</p>
                                <InfoCell number={filteredStatistics.teachersPoliticalPrisoners}>
                                    { filteredStatistics.teachersPoliticalPrisoners === 1 ? translations.statistics.professorsStats.politPrisoners.singular : translations.statistics.professorsStats.politPrisoners.plural }
                                </InfoCell>
                                <InfoCell number={filteredStatistics.teachersLostJob}>
                                    { filteredStatistics.teachersLostJob === 1 ? translations.statistics.professorsStats.fired.singular : translations.statistics.professorsStats.fired.plural }
                                </InfoCell>
                                <InfoCell number={filteredStatistics.teachersFacedThreats}>
                                    { filteredStatistics.teachersFacedThreats === 1 ? translations.statistics.professorsStats.threatened.singular : translations.statistics.professorsStats.threatened.plural }
                                </InfoCell>
                                <InfoCell number={filteredStatistics.teachersReprimanded}>
                                    { filteredStatistics.teachersReprimanded === 1 ? translations.statistics.professorsStats.reprimanded.singular : translations.statistics.professorsStats.reprimanded.plural }
                                </InfoCell>
                            </div>
                            {! screenSize.mobile &&  <div className="divider"/>}
                        </div>
                    </div>
            )}
        </AppContext.Consumer>
    )
};
