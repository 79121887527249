import React, { useState, useRef, useLayoutEffect } from 'react';
import classNames from 'classnames';

export const SpotButton = ({style = null, className, onClick, showSpot = true, src, expand = 0, offset: {top = 0, right = 0, bottom = 0, left = 0} = {}, children}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    const [spotWidth, setSpotWidth] = useState(0);

    const buttonRef = useRef();

    document.fonts.ready.then(() => {
        if (buttonRef.current) {
            setFontsLoaded(true);
        }
    })

    useLayoutEffect(() => {
        setSpotWidth(buttonRef.current.offsetWidth*1.05+expand)
    }, [fontsLoaded, expand])

    return (
        <div className='spot-button' style={style}>
            { showSpot &&
                <>
                    <img
                        src={src}
                        className={classNames('spot', {"spot-logo": isHovered})}
                        style={{width: spotWidth, margin: `${top}px ${right}px ${bottom}px ${left}px`}}
                        alt="spot"
                    /> 
                    <img
                        src={src}
                        className={classNames('spot', {"spot-logo-static": isHovered})}
                        style={{width: spotWidth, margin: `${top}px ${right}px ${bottom}px ${left}px`}}
                        alt="spot"
                        />
                    <img
                        src={src}
                        className={classNames('spot', {"spot-logo-slower": isHovered})}
                        style={{width: spotWidth, margin: `${top}px ${right}px ${bottom}px ${left}px`}}
                        alt="spot"
                    />
                </>
            }
            <button
                type="button"
                className={className}
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                ref={buttonRef}
            >
                {children}
            </button>
        </div>
    )
};
