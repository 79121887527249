import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Divider } from "@react-md/divider";
import AppContext from '../context';
import { SpotButton } from './SpotButton';
import {
    spotGuideButton,
    spotMonitoringButton,
    spotChatbotButton,
    spotFooter,
} from '../images/spots';
import telegramIcon from '../images/socialNetworks/telegramNegative.svg';
import {
    Telegram,
    Instagram,
    Facebook,
    VK,
    YouTube,
    OK,
    Viber,
    HonestPeopleRu,
    HonestPeopleBy,
    HonestPeopleEn,
    HonestUniversity
} from '../images/socialNetworks';

export const Footer = () => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        setTimeout(() => {
          setIsShown(true);
        }, 500);
      }, []);
    

    const honestPeople = {
        'ru': <HonestPeopleRu className="link hp"/>,
        'by': <HonestPeopleBy className="link hp"/>,
        'en': <HonestPeopleEn className="link hp"/>,
    }

    const firstStep = (translations, links, screenSize) => (
        <>
            <div>
                <p className="heading text-green steps">1</p>
                <p className="text text-grey"><span className={"text-green"}>{translations.footer.firstStepMain}</span>{translations.footer.firstStepAdd}</p>
            </div>
            <SpotButton
                className="button all-caps text-black steps"
                onClick={() => window.open(links.student)}
                showSpot={screenSize.desktop}
                src={spotGuideButton}
                expand={70}
                offset={{top: -15, left: -35}}
            >
                {translations.footer.firstStepButton}
            </SpotButton>
        </>
    )

    const secondStep = (translations, links, screenSize) => (
        <>
            <div>
                <p className="heading text-green steps">2</p>
                <p className="text text-grey"><span className={"text-green"}>{translations.footer.secondStepMain}</span>{translations.footer.secondStepAdd}</p>
            </div>
            <SpotButton
                className="button all-caps text-black steps monitoring-button"
                onClick={() => window.open(links.shareStory)}
                showSpot={screenSize.desktop}
                src={spotMonitoringButton}
                expand={70}
                offset={{top: -10, left: -50}}
            >
                {translations.footer.secondStepButton}
            </SpotButton>
        </>
    )

    const thirdStep = (translations, links, screenSize) => (
        <>
            <div>
                <p className="heading text-green steps">3</p>
                <p className="text text-grey"><span className={"text-green"}>{translations.footer.thirdStepMain}</span>{translations.footer.thirdStepAdd}</p>
            </div>
            <SpotButton
                className="button all-caps text-black steps"
                onClick={() => window.open(links.chatbot)}
                showSpot={screenSize.desktop}
                src={spotChatbotButton}
                expand={110}
                offset={{top: -8, left: -50}}
            >
                {translations.footer.thirdStepButton} <img src={telegramIcon} alt='tg' className='steps-tg' />
            </SpotButton>
        </>
    )

    return (
        <AppContext.Consumer>
            {({language, translations, links, screenSize}) => (
                <div id="actions" style={{}}>
                    {! screenSize.mobile && isShown && <img src={spotFooter} className='spot-footer' alt="spot" /> } 
                    <div className='content'>
                        <p className="heading mobile">{translations.footer.header}</p>
                        <div className="table">
                            {screenSize.desktop && <div className="divider"/>}
                            <div className="column width-33 steps">
                                {firstStep(translations, links, screenSize)}
                            </div>
                            {screenSize.desktop && <div className="divider"/>}
                            <div className="column width-33 steps">
                                {secondStep(translations, links, screenSize)}
                            </div>
                            {screenSize.desktop && <div className="divider"/>}
                            <div className="column width-33 steps">
                                {thirdStep(translations, links, screenSize)}
                            </div>
                            {screenSize.desktop && <div className="divider"/>}
                        </div>
                        {! screenSize.mobile &&  <Divider className="divider steps"/> }
                        <div className="table section" style={{marginTop: 0}}>
                            <div className="column width-77 steps">
                                <p className="text text-grey">{translations.footer.connection}</p>
                                <a href={"mailto:honestuniversity@gmail.com"} target="_blank" className="bold-text link-text">honestuniversity@gmail.com</a>
                                <a href={"https://t.me/HonestUniversity"} target="_blank" className="bold-text link-text">t.me/HonestUniversity</a>
                            </div>
                            <div className="column width-33 steps">
                                {   screenSize.desktop &&
                                    <p className="small-text text-grey">
                                        {translations.footer.note}
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="table social-networks">
                            <div className={classNames("links", {"column": ! screenSize.mobile, "width-77": ! screenSize.mobile})}>
                                <a href={links.telegram} target="_blank" className="link"><Telegram className="link social-network telegram" /></a>
                                <a href={links.instagram} target="_blank" className="link"><Instagram className="link social-network instagram"/></a>
                                <a href={links.facebook} target="_blank" className="link"><Facebook className="link social-network facebook"/></a>
                                <a href={links.vk} target="_blank" className="link"><VK className="link social-network vk"/></a>
                                {! screenSize.mobile && (
                                    <>
                                        <a href={links.youtube} target="_blank" className="link"><YouTube className="link social-network youtube"/></a>
                                        <a href={links.ok} target="_blank" className="link"><OK className="link social-network ok"/></a>
                                        <a href={links.viber} target="_blank" className="link"><Viber className="link social-network viber"/></a>
                                    </>                         
                                )}
                            </div>
                            {screenSize.mobile && (
                                <div className="links">
                                    <a href={links.youtube} target="_blank" className="link"><YouTube className="link social-network youtube"/></a>
                                    <a href={links.ok} target="_blank" className="link"><OK className="link social-network ok"/></a>
                                    <a href={links.viber} target="_blank" className="link"><Viber className="link social-network viber"/></a>
                                </div>
                            )}
                            <div className={classNames("links", {"column": ! screenSize.mobile, "width-33": ! screenSize.mobile})}>
                                <a href={links.honestPeople[language]} target="_blank" className="link">
                                    {honestPeople[language]}
                                </a>
                                <HonestUniversity className="link hu"/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    )
};
