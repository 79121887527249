import React, { useState } from 'react';
import classNames from 'classnames';
import unfilledDot from '../images/greenUnfilledDot.svg';
import filledDot from '../images/greenFilledDot.svg';


export const AnchorButton = ({ showDot, onClick, children }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className='anchor-button'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
        >
            {showDot && <img src={isHovered ? filledDot : unfilledDot} className="dot" alt="logo" />}
            <p className={classNames('anchor-button-text', 'text', {'text-green': isHovered})}>
                {children}
            </p>
        </div>
    )
};
