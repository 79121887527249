import AppContext from '../context';
import { spotTop } from '../images/spots';
import { numberFilter } from '../utils';

export const Main = ({ data }) => {
    return (
        <AppContext.Consumer>
            {({translations, links, screenSize}) => (
                <div className="section-main">
                    {! screenSize.mobile &&  <img src={spotTop} className='spot-top' alt="spot" />}
                    <div className='content'>
                        <p className="heading mobile">
                            <span className="heading mobile text-red">{translations.main.firstHeader} </span>
                            {translations.main.secondHeader}
                        </p>
                        <div className="table">
                            {! screenSize.mobile && <div className="divider"/>}
                            <div className="column width-50">
                                <div className="all-stories">
                                    <p className="heading in-table text-green numbers all-stories-number">{numberFilter(data.storiesNumber)}</p>
                                    <p className="all-caps text-green all-stories-text">{translations.main.numberDescription}</p>
                                </div>                                
                                <button
                                    type="button"
                                    className="button all-caps text-black share-story-main"
                                    onClick={() => window.open(links.shareStory)}
                                >
                                    {translations.main.button}
                                </button>
                            </div>
                            {! screenSize.mobile &&  <div className="divider"/>}
                            <div className="column width-50">
                                <p className="text text-grey">{translations.main.description}</p>
                            </div>
                            {! screenSize.mobile &&  <div className="divider"/>}
                        </div>
                        <p className="small-text anonymous text-grey">
                            <span className="text-white">{translations.main.firstFootnote}</span> {translations.main.secondFootnote}
                        </p>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    )
};
