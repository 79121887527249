import { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import photo from '../images/photo.svg';
import { StoryDot as FactDot } from './StoryDot'
import { spotAccomplices } from '../images/spots';
import AppContext from '../context';
import { numberFilter } from '../utils';

export const Accomplices = ({ showTable, data }) => {
    const [accompliceIndex, setAccompliceIndex] = useState(0);

    const preFacts = data.repressors || [];
    const facts = preFacts.filter(fact => fact.priority < 50);
    const factsLoaded = facts.length > 0;

    const produceDots = count => {
        const arr = [];
        for (let i=0; i<count; i++) {
            arr.push(<FactDot key={i} active={accompliceIndex === i} onClick={() => setAccompliceIndex(i)} red />)
        }
        return arr;
    }

    const fact = facts[accompliceIndex];

    const personStory = fact => (
        <div style={{textAlign: 'left'}}>
            <div className={"profile"}>
                <div className="filter filter-red">
                    <img
                        src={fact.photoUrl || photo}
                        alt='profile'
                        className="profile-photo"
                        style={{mixBlendMode: fact.photoUrl ? 'luminosity' : 'unset'}}
                    />
                </div>
                <div>
                    <p className="all-caps text-red">{fact.name}</p>
                    <p className="all-caps text-red">{fact.surname}</p>
                    <p className="text" style={{marginTop: 12}}>{fact.position} {fact.university}</p>
                </div>
            </div>
            <p className="text text-grey" style={{marginTop: 20}}>{fact.story}</p>
        </div>
    );

    const dots = (
        <div className={'dots'}>
            {produceDots(facts.length)}
        </div>
    );

    const factStatistics = (fact, translations) => (
        <div className="profile facts">
            <p className="text text-white">{translations.accomplices.deducted} <span className="text-red" style={{marginLeft: 8}}>{numberFilter(fact.expelled)}</span></p>
            <p className="text text-white">{translations.accomplices.pressureFacts} <span className="text-red" style={{marginLeft: 8}}>{numberFilter(fact.pressure)}</span></p>
        </div>
    );

    return (
        <AppContext.Consumer>
            {({translations, screenSize}) => (
                <div className="section" id="accomplices">
                    {! screenSize.mobile &&  <img src={spotAccomplices} className='spot-accomplices' alt="spot" />}
                    <div className='content'>
                        <p className="heading mobile accomplices">{translations.accomplices.header}</p>
                        {
                            factsLoaded && screenSize.desktop &&
                            <div className="table">
                                <div className="divider"/>
                                <div className="column width-33">
                                    <p className="small-text text-grey">
                                        <span className="text-red">{translations.accomplices.firstDescription}</span>
                                        <span className="text-red"></span>
                                        {translations.accomplices.secondDescription}
                                        <br/><br/>
                                        {translations.accomplices.sanctionList}
                                        <br/><br/>
                                        <span className="text-red sanction-names">{translations.accomplices.firstPerson.name} </span>
                                        <span className="text-white">{translations.accomplices.firstPerson.position}</span>
                                        <br/><br/>
                                        <span className="text-red sanction-names">{translations.accomplices.secondPerson.name} </span>
                                        <span className="text-white">{translations.accomplices.secondPerson.position}</span>
                                        <br/><br/>
                                        <span className="text-red sanction-names">{translations.accomplices.thirdPerson.name} </span>
                                        <span className="text-white">{translations.accomplices.thirdPerson.position}</span>
                                        <br/><br/>
                                        <span className="text-red sanction-names">{translations.accomplices.forthPerson.name} </span>
                                        <span className="text-white">{translations.accomplices.forthPerson.position}</span>
                                    </p>
                                </div>
                                <div className="divider"/>
                                <div className="column width-77">
                                        {personStory(fact)}
                                        {factStatistics(fact, translations)}
                                        {dots}
                                    <button className="button red all-caps text-black full-table" onClick={showTable}>{translations.accomplices.fullList}</button>
                                </div>
                                <div className="divider"/>
                            </div>
                        }
                        {
                            factsLoaded && screenSize.tablet &&
                            <div className="non-table">
                                {personStory(fact)}
                                {factStatistics(fact, translations)}
                                {dots}
                                <button className="button red all-caps text-black full-table" onClick={showTable}>{translations.accomplices.fullList}</button>
                            </div>
                        }
                        {
                            factsLoaded && screenSize.mobile && (
                                <>
                                    <Swiper className="swiper mySwiper" slidesPerView={'auto'} spaceBetween={30} onSlideChange={(swiper) => swiper.activeIndex !== facts.length && setAccompliceIndex(swiper.activeIndex)}>
                                        <div className="overlay" />
                                        {
                                            facts.map(fact => (
                                                <SwiperSlide key={fact.id}>
                                                    {personStory(fact)}
                                                </SwiperSlide>
                                            ))
                                        }
                                        <SwiperSlide className="last-slide" />
                                    </Swiper>
                                    {factStatistics(fact, translations)}
                                    <button className="button red all-caps text-black full-table" onClick={showTable}>{translations.accomplices.fullList}</button>
                                </>
                            )
                        }
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    )
};
