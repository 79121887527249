import Select from "react-select";
import classNames from 'classnames';
import arrow from '../images/arrowDropdown.svg';

export const Panel = ({options, filter, allButtonText, placeholder, onClick}) => {
    const selectStyles = {
        control: (styles) => ({ 
            ...styles, 
            padding: '0px 12px',
            borderRadius: '120px',
            height: 30,
            minHeight: 30,
            width: 215,
            boxShadow: 'none',
            borderColor: filter === 'all' ? '#FFFFFF' : '#FF4242',
            backgroundColor: filter === 'all' ? '#FFFFFF' : '#FF4242',
            display: 'flex',
            alignContent: 'center',

            ':hover': {
                cursor: 'pointer',
                backgroundColor: '#FF4242',
                borderColor: '#FF4242',
            },
        }),
        placeholder: (styles) => ({
            ...styles,
            color: '#262626',
            fontFamily: 'Loos Condensed',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: '0em',
            textAlign: 'left',
            margin: '0px',
        }),
        container: (styles) => ({
            ...styles,
            maxHeight: 30,
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            paddingTop: 2,
        }),
        valueContainer: (styles) => ({
            ...styles,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        option: (styles) => ({
            ...styles,
            color: '#262626',
            padding: 0,
            paddingLeft: 20,
            paddingTop: 3,

            ':hover': {
                cursor: 'pointer',
            }
        }),
        menu: (styles) => ({
            ...styles,
            color: '#262626',
          }),
    }

    return (
        <div className="statistics-control">
            <button
                className={classNames("button small-text text-black statistics", {'active': filter === 'all'})}
                onClick={() => onClick('all')}
            >
                {allButtonText}
            </button>
            <Select
                placeholder={placeholder}
                options={options}
                styles={selectStyles}
                isSearchable={false}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => <img src={arrow} alt="arrow" />,
                }}
                onChange={(option) => onClick(option)}
                value={filter === 'all' ? null : filter}
            />
        </div>
    )
}