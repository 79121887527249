import { animateScroll } from "./animateScroll";

const getElementPosition = (element) => element ? element.offsetTop : 0;

export const scrollTo = ({ id = null, ref = null, top = false, headerOffset = -70, duration = 1125 }) => {
  // the position of the scroll bar before the user clicks the button
  const initialPosition = window.scrollY;

  // decide what type of reference that is
  // if neither ref or id is provided  set element to null
  const element = ref ? ref.current : id ? document.getElementById(id) : null;

  if (!element && !top) {
    return;
  }

  const offset = top ? 0 : headerOffset;

  animateScroll({
    targetPosition: getElementPosition(element) - offset,
    initialPosition,
    duration
  });
};
