import React, { useState } from 'react';
import { Divider } from "@react-md/divider";
import { slide as Menu } from 'react-burger-menu'
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as Close } from '../images/close.svg';
import { AnchorButton } from './AnchorButton';
import AppContext from '../context';

export const Header = ({navigationHandler, languages}) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const mobileNavigationHandler = tag => {
        navigationHandler(tag, 1125, -20)
        setIsOpen(false);
    }

    return (
        <>
            <AppContext.Consumer>
                {({translations, links, screenSize}) => (
                    screenSize.mobile ? 
                    <>
                        <Divider className="divider top"/>
                        <div className='header-content'>
                            <Logo className="link logo" onClick={() => navigationHandler()} />
                            <Menu
                                customCrossIcon={ <Close/> }
                                isOpen={isOpen}
                                onStateChange={state => setIsOpen(state.isOpen)}
                                right
                            >
                                <div>
                                    <div className="languages">
                                        {languages}
                                    </div>
                                </div>
                                <div className='header-buttons'>
                                    <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => mobileNavigationHandler('statistics')}
                                    >
                                        {translations.menu.statistics}
                                    </AnchorButton>
                                    {/* <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => mobileNavigationHandler('stories')}
                                    >
                                        {translations.menu.stories}
                                    </AnchorButton> */}
                                    <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => mobileNavigationHandler('accomplices')}
                                    >
                                        {translations.menu.accomplices}
                                    </AnchorButton>
                                    <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => mobileNavigationHandler('actions')}
                                    >
                                        {translations.menu.actions}
                                    </AnchorButton>
                                </div>
                                <button
                                    type="button"
                                    className="button all-caps text-black share-story-main"
                                    onClick={() => window.open(links.shareStory)}
                                >
                                    {translations.main.button}
                                </button>
                            </Menu>
                        </div>
                    </>
                    :
                    <>
                        <div className="work-space">
                            <div className="languages">
                                {languages}
                            </div>
                            <Divider className="divider"/>
                        </div>
                        <div className='header'>
                            <div className='work-space header-content'>
                                <Logo className="link logo" onClick={() => navigationHandler()}/>
                                <div className='header-buttons'>
                                    <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => navigationHandler('statistics')}
                                    >
                                        {translations.menu.statistics}
                                    </AnchorButton>
                                    {/* <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => navigationHandler('stories')}
                                    >
                                        {translations.menu.stories}
                                    </AnchorButton> */}
                                    <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => navigationHandler('accomplices')}
                                    >
                                        {translations.menu.accomplices}
                                    </AnchorButton>
                                    <AnchorButton
                                        showDot={screenSize.desktop}
                                        onClick={() => navigationHandler('actions')}
                                    >
                                        {translations.menu.actions}
                                    </AnchorButton>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </AppContext.Consumer>
        </>
    )
};
