import React, { useState } from 'react';
import inactiveDot from '../images/inactiveDot.svg';
import greenUnfilledDot from '../images/greenUnfilledDot.svg';
import greenFilledDot from '../images/greenFilledDot.svg';
import redUnfilledDot from '../images/redUnfilledDot.svg';
import redFilledDot from '../images/redFilledDot.svg';

export const StoryDot = ({active, onClick, red}) => {
    const [isHovered, setIsHovered] = useState(false);
    const filledDot = red ? redFilledDot : greenFilledDot;
    const unfilledDot = red ? redUnfilledDot : greenUnfilledDot;

    return (
        <img
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            src={active ? filledDot : isHovered ? unfilledDot : inactiveDot}
            onClick={onClick}
            className="dot"
            alt="logo"
        />
    )
};
