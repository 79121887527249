import Media from 'react-media';
import classNames from 'classnames';
import AppContext from '../context';
import logo from '../images/spots/spotShareButton.svg';
import { SpotButton } from './SpotButton';

export const ShareStory = () => {
    const MEDIA_QUERIES = {
        mobile: "(max-width: 719px)",
        tablet: "(min-width: 720px) and (max-width: 1023px)",
        '1024p': "(min-width: 1024px) and (max-width: 1279px)",
        '1280p': "(min-width: 1280px) and (max-width: 1439px)",
        '1440p': "(min-width: 1440px)"
    };

    const newLineButton = (screenSize, language) => (
        screenSize.mobile || screenSize.tablet || 
        (screenSize['1440p'] && language === 'en') ||
        (! (screenSize['1440p'] || screenSize['1280p']) && language === 'by') 
    );

    return (
        <Media queries={MEDIA_QUERIES}>
            {screenSize => (
                <AppContext.Consumer>
                    {({translations, links, language}) => (
                        <div className="section">
                            <p className="heading mobile" style={{display: 'inline', verticalAlign: 'middle'}}>
                                <span className={"text-green"}>{translations.shareStory.firstHeader}</span>
                                {translations.shareStory.secondHeader}
                            </p>
                            <SpotButton
                                style={newLineButton(screenSize, language) ? {marginTop: 40} : {display: 'inline'}}
                                className={classNames("button all-caps text-black share-story button-inline", {'new-line': newLineButton(screenSize, language)})}
                                onClick={() => window.open(links.shareStory)}
                                src={logo}
                                expand={screenSize.mobile ? 130 : screenSize.tablet ? 115 : (screenSize['1024p'] && ! newLineButton(screenSize, language)) ? 110 : 150}
                                offset={{
                                    top: newLineButton(screenSize, language) ? -60 : -45,
                                    left: screenSize.tablet ? -60 : newLineButton(screenSize, language) ? -75 : 0
                                }}
                            >
                                {translations.main.button}
                            </SpotButton>
                            <p className="small-text text-grey share-story-footnote">
                                {translations.shareStory.footnote}
                            </p>
                        </div>
                    )}
                </AppContext.Consumer>
            )}
        </Media>
    )
};
