import React from 'react';

const AppContext = React.createContext({});

export const translations = {
    'ru': {
        title: 'Университет — не тюрьма',
        menu: {
            statistics: 'Статистика',
            stories: 'Истории',
            accomplices: 'Соучастники преступлений',
            actions: 'Действия',
        },
        languages: {
            ru: 'Рус',
            by: 'Бел',
            en: 'Eng',
        },
        main: {
            firstHeader: 'Репрессии против студентов и\xa0преподавателей в\xa0Беларуси.',
            secondHeader: 'Как\xa0уничтожают свободу в\xa0вузах и\xa0кто за\xa0этим стоит',
            description: 'Чтобы привлечь к\xa0ответственности виновных и\xa0помочь пострадавшим, Честный университет собирает любую информацию о\xa0преследованиях и\xa0нарушениях со\xa0стороны администраций вузов.',
            numberDescription: 'Историй собрано',
            button: 'Поделиться историей',
            firstFootnote: 'Мы гарантируем анонимность.',
            secondFootnote: 'Без\xa0вашего согласия информация не\xa0будет опубликована или\xa0передана третьим лицам.',
        },
        statistics: {
            header: 'Университет\nстрогого режима',
            numberDescription: 'студентов и\xa0преподавателей стали жертвами репрессий или\xa0столкнулись с\xa0давлением внутри университетов',
            footnote: 'Данные, собранные из\xa0открытых источников, а\xa0также истории пострадавших, верифицированные командой проекта.',
            description: 'Сотрудники администраций вузов отчисляют неугодных студентов и\xa0увольняют преподавателей, выступают с\xa0ложными показаниями в\xa0судах, пишут надуманные заявления в\xa0силовые органы и\xa0угрожают в\xa0личных беседах.',
            allCasesButton: 'Все случаи',
            filterButton: 'Сортировать по\xa0вузу',
            students: 'Давление на\xa0студентов и\xa0учащихся',
            studentsStats: {
                politPrisoners: {
                    singular: 'Признан\nполитзаключённым',
                    plural: 'Признаны\nполитзаключёнными',
                },
                expelled: {
                    singular: 'Был\nотчислен',
                    plural: 'Были\nотчислены',
                },
                stripped: {
                    singular: 'Лишён стипендии\nи/или общежития',
                    plural: 'Лишены стипендий\nи/или общежитий',
                },
                subjected: {
                    singular: 'Прошёл через\nпрофилактические беседы',
                    plural: 'Прошли через\nпрофилактические беседы',
                },
                reprimanded: {
                    singular: 'Получил\nвыговор',
                    plural: 'Получили\nвыговоры',
                },
            },
            professors: 'Давление на\xa0учёных и\xa0преподавателей',
            professorsStats: {
                politPrisoners: {
                    singular: 'Признан\nполитзаключённым',
                    plural: 'Признаны\nполитзаключёнными',
                },
                fired: {
                    singular: 'Потерял\nработу',
                    plural: 'Потеряли\nработу',
                },
                threatened: {
                    singular: 'Столкнулся\nс угрозами',
                    plural: 'Столкнулись\nс угрозами',
                },
                reprimanded: {
                    singular: 'Получил\nвыговор',
                    plural: 'Получили\nвыговоры',
                },
            },
        },
        shareStory: {
            firstHeader: 'Если ваши права были нарушены — поделитесь своей историей. ',
            secondHeader: 'Волонтеры свяжутся с\xa0вами в\xa0течение 12\xa0часов и\xa0расскажут, как получить помощь',
            button: 'Поделиться историей',
            footnote: 'Cобранную информацию мы отправляем зарубежным университетам, которые сотрудничают с\xa0беларускими вузами, используем для\xa0расширения программ помощии сохраняем, чтобы в\xa0будущем привлечь виновных к\xa0ответственности.',
        },
        stories: {
            header: 'Пострадавшие из-за\nгражданской позиции',
            firstDescription: 'Скорая взаимопомощь — площадка для\xa0прямой помощи пострадавшим. ',
            secondDescription: 'В\xa0отличие от\xa0любого фонда, вы делаете прямой перевод на\xa0карту человека, который потерял работу или\xa0был отчислен из-за\xa0гражданской позиции.',
            button: 'Помочь',
        },
        accomplices: {
            header: 'Соучастники\nпреступлений',
            firstDescription: 'Чтобы привлечь виновных к\xa0ответственности, мы собираем информацию о\xa0всех сотрудниках университетов, которые причастны к\xa0репрессиям. ',
            secondDescription: 'Доказательная база Честного университета состоит из\xa0фотографий незаконных приказов, видео- и\xa0аудиозаписей угроз, свидетельства пострадавших.\n\nБлагодаря собранной информации 21\xa0июня 2021\xa0года проректор БГЭУ, ректоры БГАИ, БГМУ и\xa0БрГТУ были включены в\xa0четвёртый пакет санкций Евросоюза за\xa0участие в\xa0преступлениях против студентов и\xa0преподавателей своих университетов.',
            sanctionList: 'В\xa0санкционный список попали:',
            firstPerson: {
                name: 'Сергей Скриба',
                position: 'проректор БГЭУ',
            },
            secondPerson: {
                name: 'Сергей Рубникович',
                position: 'ректор БГМУ',
            },
            thirdPerson: {
                name: 'Александр Баханович',
                position: 'ректор БрГТУ',
            },
            forthPerson: {
                name: 'Михаил Борозна',
                position: 'ректор БГАИ',
            },
            deducted: 'Отчислений',
            pressureFacts: 'Факты давления',
            fullList: 'Полный список таблицей',
        },
        table: {
            header: 'Соучастники преступлений',
            backButton: 'На главную',
            name: 'Имя',
            position: 'Должность',
            university: 'Университет',
            expelled: 'Отчисления',
            pressure: 'Давление',
            choice: 'Выберите университет',
            allCasesButton: 'Все соучастники',
        },
        footer: {
            header: 'Что делать, если ваши права нарушают',
            firstStepMain: 'Изучите юр-памятку',
            firstStepAdd: ', чтобы понимать, когда ваши права нарушают',
            firstStepButton: 'Памятка',
            secondStepMain: 'Фиксируйте давление',
            secondStepAdd: ' через форму мониторинга и\xa0помощи',
            secondStepButton: 'Форма мониторинга',
            thirdStepMain: 'Регистрируйтесь в\xa0чат-боте «Универ»',
            thirdStepAdd: ', чтобы держать связь с\xa0активистами своего университета',
            thirdStepButton: 'Чат-бот',
            connection: 'Для связи с\xa0командой',
            note: 'Помните, что университет — это студенты и\xa0преподаватели, а\xa0не\xa0стены, администрация или\xa0дорожные карты. Мы считаем необходимой огласку происходящего, помощь пострадавшими борьбу с\xa0узурпацией власти ректором, идеологами или\xa0силовыми структурами.',
        },
    },
    'by': {
        title: 'Універсітэт — не турма',
        menu: {
            statistics: 'Статыстыка',
            stories: 'Гісторыі',
            accomplices: 'Саўдзельнікі злачынстваў',
            actions: 'Дзеянні',
        },
        languages: {
            ru: 'Рус',
            by: 'Бел',
            en: 'Eng',
        },
        main: {
            firstHeader: 'Рэпрэсіі супраць студэнтаў і\xa0выкладчыкаў у\xa0Беларусі.',
            secondHeader: 'Як\xa0знішчаюць свабоду ва ўніверсітэтах і\xa0хто за\xa0гэтым стаіць',
            description: 'Каб прыцягнуць да адказнасці вінаватых і\xa0дапамагчы пацярпелым, Сумленны ўніверсітэт збірае любую інфармацыю аб\xa0пераследаваннях і\xa0парушэннях з\xa0боку адміністрацый ВНУ.',
            numberDescription: 'Гісторый сабрана',
            button: 'Падзяліцца гісторыяй',
            firstFootnote: 'Мы гарантуем ананімнасць.',
            secondFootnote: 'Без вашае згоды інфармацыя не\xa0будзе апублікавана альбо\xa0перададзена іншым асобам.',
        },
        statistics: {
            header: 'Універсітэт\nсуровага рэжыму',
            numberDescription: 'студэнтаў і\xa0выкладчыкаў зрабіліся ахвярамі рэпрэсій альбо\xa0сутыкнуліся з\xa0ціскам унутры ўніверсітэтаў',
            footnote: 'Дадзеныя, сабраныя з\xa0адкрытых крыніц, а\xa0таксама гісторыі пацярпелых, верыфікаваныя камандай праекта.',
            description: 'Супрацоўнікі адміністацый ВНУ адлічваюць нежаданых студэнтаў і\xa0звальняюць выкладчыкаў, выступаюць з\xa0хлуслівымі паказаннямі ў\xa0судах, пішуць надуманыя заявы ў\xa0сілавыя органы і\xa0пагражаюць у\xa0прыватных гутарках.',
            allCasesButton: 'Усе выпадкі',
            filterButton: 'Сартаваць па\xa0ВНУ',
            students: 'Ціск на\xa0студэнтаў і\xa0навучэнцаў:',
            studentsStats: {
                politPrisoners: {
                    singular: 'Прызнаны\nпалітзняволеным',
                    plural: 'Прызнаныя\nпалітвязнямі',
                },
                expelled: {
                    singular: 'Быў\nадлічаны',
                    plural: 'Былi\nадлічаныя',
                },
                stripped: {
                    singular: 'Пазбаўлены стыпендыі\nды/альбо інтэрната',
                    plural: 'Пазбаўленыя стыпендый\nды/альбо інтэрнатаў',
                },
                subjected: {
                    singular: 'Прайшоў праз\nпрафілактычную размову',
                    plural: 'Прайшлі праз\nпрафілактычныя размовы',
                },
                reprimanded: {
                    singular: 'Атрымаў\nвымову',
                    plural: 'Атрымалі\nвымовы',
                },
            },
            professors: 'Ціск на\xa0навукоўцаў і\xa0выкладчыкаў:',
            professorsStats: {
                politPrisoners: {
                    singular: 'Прызнаны\nпалітзняволеным',
                    plural: 'Прызнаныя\nпалітвязнямі',
                },
                fired: {
                    singular: 'Згубіў\nпрацу',
                    plural: 'Згубілі\nпрацу',
                },
                threatened: {
                    singular: 'Сутыкнуўся\nз пагрозамі',
                    plural: 'Сутыкнуліся\nз пагрозамі',
                },
                reprimanded: {
                    singular: 'Атрымаў\nвымову',
                    plural: 'Атрымалі\nвымовы',
                },
            },
        },
        shareStory: {
            firstHeader: 'Калі вашы правы былі парушаныя - падзяліцеся сваёй гісторыяй. ',
            secondHeader: 'Валанцёры звяжуцца з\xa0вамі на\xa0працягу 12\xa0гадзін і\xa0раскажуць, як атрымаць дапамогу',
            button: 'Падзяліцца гісторыяй',
            footnote: 'Сабраную інфармацыю мы адпраўляем замежным універсітэтам, якія супрацоўнічаюць з\xa0беларускімі ВНУ, выкарыстоўваем дзеля\xa0пашырэння праграм дапамогі і\xa0захоўваем, каб у\xa0будучым прыцягнуць вінаватых да\xa0адказнасці.',
        },
        stories: {
            header: 'Пацярпелыя праз\nграмадзянскую пазіцыю',
            firstDescription: '“Хуткая ўзаемадапамога” — пляцоўка дзеля\xa0непасрэднай дапамогі пацярпелым. ',
            secondDescription: 'У\xa0адрозненне ад\xa0любога фонда, вы робіце непасрэды пералiк на\xa0картку чалавека, які згубіў працу ці\xa0быў адлічаны праз грамадзянскую пазіцыю.',
            button: 'Дапамагчы',
        },
        accomplices: {
            header: 'Саўдзельнікі\nзлачынстваў',
            firstDescription: 'Каб прыцягнуць вінаватых да\xa0адказнасці, мы збіраем інфармацыю пра\xa0ўсіх супрацоўнікаў універсітэтаў, якія маюць дачыненне да\xa0рэпрэсіяў. ',
            secondDescription: 'База доказаў Сумленнага ўніверсітэта складаецца з\xa0фотавыяваў незаконных загадаў, відэа- і\xa0аўдыязапісаў пагроз, сведчанняў пацярпелых.\n\nДзякуючы сабранай інфармацыі, 21\xa0чэрвеня 2021\xa0года прарэктар БДЭУ, рэктары БДАІ, БДМУ і\xa0БрДТУ былі даданыя да\xa0чацвёртага пакета санкцыяў Еўразвязу за\xa0ўдзел у\xa0злачынствах супраць студэнтаў і\xa0выкладчыкаў сваіх універсітэтаў.',
            sanctionList: 'У\xa0санкцыйны спіс трапілі:',
            firstPerson: {
                name: 'Сяргей Скрыба',
                position: 'прарэктар БДЭУ',
            },
            secondPerson: {
                name: 'Сяргей Рубніковіч',
                position: 'рэктар БДМУ',
            },
            thirdPerson: {
                name: 'Аляксандр Бахановіч',
                position: 'рэктар БрДТУ',
            },
            forthPerson: {
                name: 'Міхаіл Баразна',
                position: 'рэктар БДАІ',
            },
            deducted: 'Адлічэнняў',
            pressureFacts: 'Факты ціску',
            fullList: 'Поўны спіс табліцай',
        },
        table: {
            header: 'Саўдзельнікі злачынстваў',
            backButton: 'На\xa0галоўную',
            name: 'Iмя',
            position: 'Пасада',
            university: 'Універсітэт',
            expelled: 'Адлічэннi',
            pressure: 'Ціск',
            choice: 'Абярыце ўніверсітэт',
            allCasesButton: 'Усе саўдзельнікі',
        },
        footer: {
            header: 'Што рабіць, калі вашы\nправы парушаюць',
            firstStepMain: 'Вывучыце юрыдычную памятку',
            firstStepAdd: ', каб разумець, калі вашы правы парушаюць',
            firstStepButton: 'Памятка',
            secondStepMain: 'Фіксуйце ціск',
            secondStepAdd: ' праз форму маніторынга і\xa0дапамогі',
            secondStepButton: 'Форма маніторынга',
            thirdStepMain: 'Рэгіструйцеся ў\xa0чат-боце «Універ»',
            thirdStepAdd: ', каб трымаць сувязь з\xa0актывістамі свайго ўніверсітэта',
            thirdStepButton: 'Чат-бот',
            connection: 'Сувязь з\xa0камандай',
            note: 'Памятайце, што ўніверсітэт — гэта студэнты і\xa0выкладчыкі, а\xa0не\xa0муры, адміністрацыя альбо падарожныя карты. Мы лічым неабходным раскрываць тое, што адбываецца, дапамагаць пацярпелым і\xa0змагацца з\xa0узурпацыяй улады рэктарамi, ідэёлагамi ці\xa0сілавымі структурамі.',
        },
    },
    'en': {
        title: 'University is not a prison',
        menu: {
            statistics: 'Statistics',
            stories: 'Stories',
            accomplices: 'Accomplices',
            actions: 'Actions',
        },
        languages: {
            ru: 'Ru',
            by: 'By',
            en: 'En',
        },
        main: {
            firstHeader: 'Repression of\xa0students and\xa0professors in\xa0Belarus.',
            secondHeader: 'How\xa0freedom is being destroyed in universities and\xa0who’s behind\xa0it',
            description: 'To bring the perpetrators to justice and help the victims, Honest University collects any information about persecution and violations by university administrations. ',
            numberDescription: 'Stories collected',
            button: 'Share your story',
            firstFootnote: 'We guarantee anonymity.',
            secondFootnote: 'The information will not be published or transferred to third parties without your consent.',
        },
        statistics: {
            header: 'Supermax University',
            numberDescription: 'students and\xa0professors have been subjected to\xa0repression or\xa0pressure at\xa0universities.',
            footnote: 'The\xa0data collected from\xa0open sources and\xa0the\xa0victims’ stories are verified by\xa0the\xa0project team.',
            description: 'The\xa0administrative staff of\xa0the\xa0universities expel unwanted students and\xa0dismiss professors, give false testimony in\xa0courts, write fictitious statements to\xa0law enforcement agencies, and\xa0threaten in\xa0personal conversations.',
            allCasesButton: 'All cases',
            filterButton: 'Sort by\xa0university',
            students: 'Instances of\xa0persecution – students:',
            studentsStats: {
                politPrisoners: {
                    singular: 'Recognized as\npolitical prisoners',
                    plural: 'Recognized as\npolitical prisoners',
                },
                expelled: {
                    singular: 'Expelled',
                    plural: 'Expelled',
                },
                stripped: {
                    singular: 'Stripped of scholarships\nor evicted from their dorms',
                    plural: 'Stripped of scholarships\nor evicted from their dorms',
                },
                subjected: {
                    singular: 'Subjected to “prophylactic\nconversations”',
                    plural: 'Subjected to “prophylactic\nconversations”',
                },
                reprimanded: {
                    singular: 'Reprimanded',
                    plural: 'Reprimanded',
                },
            },
            professors: 'Instances of\xa0persecution – professors:',
            professorsStats: {
                politPrisoners: {
                    singular: 'Recognized as\npolitical prisoners',
                    plural: 'Recognized as\npolitical prisoners',
                },
                fired: {
                    singular: 'Lost\ntheir job',
                    plural: 'Lost\ntheir jobs',
                },
                threatened: {
                    singular: 'Faced\nthreats',
                    plural: 'Faced\nthreats',
                },
                reprimanded: {
                    singular: 'Reprimanded',
                    plural: 'Reprimanded',
                },
            },
        },
        shareStory: {
            firstHeader: 'If\xa0your rights have been violated, share your story. ',
            secondHeader: 'Volunteers will contact you within 12\xa0hours and\xa0tell you how to get help',
            button: 'Share your story',
            footnote: 'We send the\xa0collected information to\xa0the foreign universities that\xa0cooperate with\xa0Belarusian ones; we also use it to\xa0expand assistance programs, and\xa0we will keep it to\xa0bring the\xa0perpetrators to\xa0justice in\xa0the\xa0future.',
        },
        stories: {
            header: 'Those who have suffered\nfor their active civic position',
            firstDescription: 'Mutual Emergency Aid is a\xa0platform for\xa0direct assistance to\xa0victims. ',
            secondDescription: 'Unlike with a\xa0fund, you will make a\xa0direct transfer to\xa0the\xa0card of a\xa0person who has lost that one’s job or\xa0has been expelled because of an\xa0active civic position.',
            button: 'Help',
        },
        accomplices: {
            header: 'Accomplices',
            firstDescription: 'To\xa0bring the\xa0perpetrators to\xa0justice, we collect information about all the\xa0university employees who have been involved in\xa0the\xa0repressions. ',
            secondDescription: 'The\xa0evidence base of\xa0Honest University consists of\xa0photographs of\xa0illegal orders, video and\xa0audio recordings of\xa0threats, testimonies of\xa0victims, etc.\n\nThanks to\xa0the\xa0collected information, the\xa0vice-rector of\xa0the\xa0Belarus State Economic University\xa0(BSEU), the\xa0rectors of\xa0the\xa0Belarusian State Academy of\xa0Arts\xa0(BSAA), the\xa0Belarusian State Medical University\xa0(BSMU), and\xa0the\xa0Brest State Technical University\xa0(BrSTU) were included in\xa0the\xa0fourth package of\xa0EU sanctions on\xa021\xa0June\xa02021 for\xa0participating in\xa0crimes against\xa0students and\xa0professors of\xa0their universities.',
            sanctionList: 'Included in\xa0the sanctions list:',
            firstPerson: {
                name: 'Sergey Skriba',
                position: 'Vice\xa0Chancellor\xa0of\xa0BSEU',
            },
            secondPerson: {
                name: 'Sergey Rubnikovich',
                position: 'Rector\xa0of\xa0BSMU',
            },
            thirdPerson: {
                name: 'Alexander Bakhanovich',
                position: 'Rector\xa0of\xa0BrSTU',
            },
            forthPerson: {
                name: 'Mikhail Borozna',
                position: 'Rector\xa0of\xa0BSAA',
            },
            deducted: 'Expulsions cases',
            pressureFacts: 'Intimidation cases',
            fullList: 'Full table list',
        },
        table: {
            header: 'Accomplices',
            backButton: 'To the main page',
            name: 'Name',
            position: 'Position',
            university: 'University',
            expelled: 'Expulsions',
            pressure: 'Intimidation',
            choice: 'Choose university',
            allCasesButton: 'All accomplices',
        },
        footer: {
            header: 'What to do if your rights\nare violated',
            firstStepMain: 'Study the\xa0legal guide',
            firstStepAdd: ' to\xa0understand when your rights are\xa0being violated',
            firstStepButton: 'The guide',
            secondStepMain: 'Submit instances of\xa0persecution',
            secondStepAdd: ' through the\xa0monitoring and\xa0assistance form',
            secondStepButton: 'Monitoring form',
            thirdStepMain: 'Sign\xa0in to\xa0the\xa0UNIVER chatbot',
            thirdStepAdd: ' to\xa0keep in\xa0touch with the\xa0activists of\xa0your university',
            thirdStepButton: 'Chatbot',
            connection: 'Contact our team',
            note: 'Remember that university is about\xa0students and\xa0teachers, and\xa0not about\xa0walls, administration, or\xa0“roadmaps”. We deem it necessary to\xa0publicize ongoing, arbitrary breaches of\xa0legal norms, help the\xa0victims and\xa0fight against the\xa0usurpation of\xa0power by\xa0rectors, ideologues or\xa0security forces.',
        },
    },
}

export const links = {
    shareStory: 'https://bit.ly/чу_мониторинг',
    student: 'https://student.honest-people.by/',
    chatbot: 'https://t.me/univer_by_bot',
    telegram: 'https://t.me/honestpeople_by',
    instagram: 'https://www.instagram.com/honestpeople.by',
    facebook: 'https://www.facebook.com/honestpeople.by',
    vk: 'https://vk.com/public196036808',
    youtube: 'https://www.youtube.com/c/HonestPeople/',
    ok: 'https://ok.ru/group/58137383796844',
    viber: 'https://invite.viber.com/?g2=AQAMihtU9CpYkUugb4s%2BH2DQraCk4Q%2BUst1wXPON1wIHaKnXQsrj9cvYuZUzbUET',
    honestPeople: {
        ru: 'https://honest-people.by',
        by: 'https://honest-people.by',
        en: 'https://honest-people.by/en',
    },
}

export default AppContext;
